export const languages = {
  en: 'en',
  en_US: 'en-US',
  fr_CA: 'fr-CA',
  fr: 'fr',
};

export const namespaces = {
  common: 'common',
};
