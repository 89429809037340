import { Role } from 'models/Role';
import pino from 'pino';
import { useEffect, useReducer, useRef } from 'react';
import { UIMessageProps } from 'services/UI';
import { UserMsg } from 'strings';

const MOCK_API_DELAY = 500; // ms

/**
 * Better 'typeof'.
 * @param {any} exp Expression
 * @returns {string} The type of the expression
 */
function type(exp: any): string {
  const clazz = Object.prototype.toString.call(exp);
  return clazz.substring(8, clazz.length - 1).toLowerCase();
}

const primitives = 'string number boolean symbol';
const all_types = 'object function array regexp ' + primitives;

/**
 * Utility object for declarative type checking.
 */
export const is: { [key: string]: (exp: any) => boolean } = {};
all_types.split(' ').forEach((t) => {
  is[t] = (exp) => type(exp) === t;
});
is.primitive = (exp) => primitives.includes(type(exp));
is.empty = (exp) =>
  is.array(exp) || is.string(exp)
    ? exp.length === 0
    : is.object(exp)
    ? Object.keys(exp).length === 0
    : false;
// @ts-ignore
is.not = Object.fromEntries(
  // @ts-ignore
  Object.entries(is).map(([k, f]) => [k, (exp) => !f(exp)])
);
is.def = (ref) => ref !== undefined && ref !== null;
is.nil = (ref) => !is.def(ref);

function getInstanceMethodNames(obj: Object) {
  function hasMethod(obj: Object, name: string) {
    const desc = Object.getOwnPropertyDescriptor(obj, name);
    return !!desc && typeof desc.value === 'function';
  }

  const methods: string[] = [];
  let proto = Object.getPrototypeOf(obj);

  while (proto) {
    for (const name of Object.getOwnPropertyNames(proto)) {
      name !== 'constructor' && hasMethod(proto, name) && methods.push(name);
    }
    proto = Object.getPrototypeOf(proto);
  }

  return methods;
}

export function bindMethodsToSelf(obj: Object) {
  getInstanceMethodNames(obj).forEach((method) => {
    // @ts-ignore
    obj[method] = obj[method].bind(obj);
  });
}

export function sanitizeFilename(filename: string) {
  return filename.toLowerCase().trim().replace(/ /g, '_');
}

export function delay<T>(
  ret: T | (() => T),
  delay = MOCK_API_DELAY
): Promise<T> {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      if (ret instanceof Function)
        try {
          resolve(ret());
        } catch (err) {
          reject(err);
        }
      else resolve(ret);
    }, delay)
  );
}

export function pickOne(arr: string[] | string) {
  return arr[random(2) % arr.length];
}

export function random(digits = 1) {
  return Math.floor(Math.random() * 10 ** digits);
}

export function extendSchemaNamespace(
  obj = {} as any,
  extensions = [] as any[]
) {
  // if possible we use labels as keys, but snake_cased
  extensions.map(
    (ext, i) =>
      (obj[ext.label?.trim().replace(/\s+/g, '_').toLowerCase() || i] = ext)
  );
}

export function capitalize(s: string) {
  return s[0].toUpperCase() + s.substring(1);
}

export function camelCaseToWords(s: string): string {
  const insertSpace = s.replace(/(^[A-Z])/g, ' $1');
  return insertSpace.charAt(0).toUpperCase() + insertSpace.slice(1);
}

const forceUpdateReducer = (i: number) => i + 1;

export const useForceUpdate = () => {
  const [, forceUpdate] = useReducer(forceUpdateReducer, 0);
  return forceUpdate;
};

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
};

export const logger = pino({
  // see https://getpino.io/#/docs/browser
  browser: {
    asObject: false, // set this flag to use a pino-like log object
    transmit: {
      level: 'warn',
      // TODO use this function to integrate with some monitoring service (ie bugsnug)
      send: function (level, logEvent) {
        if (level === 'warn') {
          // handle warn
        }
        if (logEvent.level.value >= 50) {
          // handle error and fatal
        }
      },
    },
  },
});

export const catchAndThrowErrors = (
  error: any,
  showError: (msg?: UIMessageProps) => void,
  displayMsg: UserMsg
) => {
  if (error.statusCode === 400) {
    if (error.message) {
      showError({
        title: displayMsg.title,
        msg: error.message,
      });
    } else {
      showError(displayMsg);
    }
  } else {
    showError();
  }
};

export const handleJsonResponse = async (response: Response) => {
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`${errorText}`);
  }
  return response.json();
};

export function toLowerCaseIfString<T>(
  value: T
): T extends string | null ? string : T {
  if (value === null) {
    return '' as T extends string | null ? string : T;
  }
  if (typeof value === 'string') {
    return value.toLowerCase() as T extends string | null ? string : T;
  }
  return value as T extends string | null ? string : T;
}

export enum Features {
  RefreshDealer,
  GetAllBrands,
  DeleteProject,
  Orders,
  GetBase64Image,
  SelectPoolShape,
  DownloadDxf,
  SaveProject,
}
type FeatureWithLimitedUsers = {
  [key in Features]: Role[];
};

const featureLimitedUserMap: FeatureWithLimitedUsers = {
  [Features.RefreshDealer]: [Role.DESIGN],
  [Features.GetAllBrands]: [Role.USER, Role.SALES, Role.DESIGN],
  [Features.DeleteProject]: [Role.USER, Role.SALES, Role.CUST_SERV],
  [Features.Orders]: [Role.SALES],
  [Features.GetBase64Image]: [Role.USER, Role.SALES],
  [Features.SelectPoolShape]: [Role.SALES, Role.DESIGN],
  [Features.DownloadDxf]: [Role.USER, Role.ADMIN],
  [Features.SaveProject]: [Role.DESIGN],
};

export const checkFeatureAuthorization = (
  feature: Features,
  userRole: String | undefined
) => {
  if (userRole === undefined) {
    return false;
  }
  const authorizedRoles = featureLimitedUserMap[feature];
  return !authorizedRoles.includes(userRole as Role);
};
